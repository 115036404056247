import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const JoinGroupScreen = () => {
  const { groupId } = useParams();
  useEffect(() => {
    joinGroup();
  }, [groupId]);

  // Redirect to the App Store
  const joinGroup = () => {
    console.log("join group number ", groupId)

    window.location.href = 'https://apps.apple.com/app/id6479351005';
  };

  return (
    <div className='bg-[#212121] h-svh flex flex-col items-center py-8 gap-4'>
      <img
          className='absolute top-0 left-0 w-full h-full'
          src={`${process.env.PUBLIC_URL}/assets/blur.png`}
          alt='blur background'
        />
      <h1 className='text-4xl text-white font-semibold'>Private Widget</h1>
      <p className='text-xl text-white font-semibold'>You got invited to join a group</p>
      <h1 onClick={() => joinGroup()} className=' flex flex-1 text-2xl text-white font-semibold items-center underline z-10'>Join the group</h1>
      <div className='flex flex-1 justify-center items-center'>
        <img
          className='h-[50svh] z-10'
          src={`${process.env.PUBLIC_URL}/assets/app_group.png`}
          alt='App screenshot'
        />
      </div>
      
    </div>
  );
};

export default JoinGroupScreen;