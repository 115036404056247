const HomeScreen = () => {
  return (
    <div className='bg-[#212121] h-svh flex flex-col items-center py-8 gap-4'>
      <img
          className='absolute top-0 left-0 w-full h-full'
          src={`${process.env.PUBLIC_URL}/assets/blur.png`}
          alt='blur background'
        />
      <h1 className='text-4xl text-white font-semibold text-center'>Private Widget</h1>
      <h2 className='text-xl text-white font-semibold px-2 text-center'>Keep your friends on your home screen</h2>
      <div className='flex flex-1 justify-center items-center'>
        <img
          className='max-h-[65svh] z-10 px-2'
          src={`${process.env.PUBLIC_URL}/assets/app_2.png`}
          alt='App screenshot'
        />
      </div>
      <a href='https://apps.apple.com/app/id6479351005' target='_blank' rel='noopener noreferrer' className='z-10'>
        <img
          className='h-[10svh]'
          src={`${process.env.PUBLIC_URL}/assets/apple.png`}
          alt='download app'
        />
      </a>
    </div>
  );
};

export default HomeScreen;